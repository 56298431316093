/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_SERVICES, GET_SERVICE} from '../Queries/index.js';
import {SERVICE_IMAGE_URL,SERVER_URL} from '../../../Config/index';
import moment from 'moment';

class Service {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace videa
     * @param {*} addService funkce z apollo, pomocí které se posílají data na server
     */

    addService = (addService) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   

        if(data.photo && data.serviceID == 0 || data.serviceID != 0)
        {  
            let langs = [];
            for(const lang of data.langs){
                langs.push({
                    name: lang.name,
                    price: lang.price,
                    discountPrice: lang.discountPrice,
                    lang:lang.lang,
                    emailText:lang.emailText,
                    description:lang.description,
                    sampleFile: lang.sampleFile,
                    file: lang.file
                });
            }

            var discountDateTo = null;
            if(data.discountDateTo && data.discountDateTo != "")
                discountDateTo = moment(data.discountDateTo).format("YYYY-MM-DD HH:mm:ss");

            var discountDateFrom = null;
            if(data.discountDateFrom && data.discountDateFrom != "")
                discountDateFrom = moment(data.discountDateFrom).format("YYYY-MM-DD HH:mm:ss");

            addService({
                variables:{
                    serviceID:data.serviceID,
                    sellAfterMainProduct:data.sellAfterMainProduct,
                    selectedServicesToBuy:data.selectedServicesToBuy,
                    photo:data.photo,
                    news:parseInt(data.news),
                    privileged:parseInt(data.privileged),
                    discountDateTo:discountDateTo,
                    discountDateFrom:discountDateFrom,
                    langs
                }
            });
        }
        else
        {
            notify.setNotification(null,"Dej tam i fotku, což ? Bude to hezčí :)",false,true,this.parent.props.client);
        }  
    } 


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response)
    {
        var resp = response.data.deleteServices.split(",");

        const { allServices } = cache.readQuery({ query: GET_SERVICES ,variables:{lang:'cz'}});
        var arr = [...allServices];
        
        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.serviceID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
        
        cache.writeQuery({ 
            query:GET_SERVICES,
            variables:{lang:'cz'},
            data:{
                allServices: [...arr]
            } 
        });

    }
    
    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allServices } = cache.readQuery({ query: GET_SERVICES,variables:variables });
        
        if(!(selectedID && selectedID != 0))
        {
            cache.writeQuery({ 
                query:GET_SERVICES,
                variables:variables,
                data:{
                    allServices: [response.data.addEditService,...allServices]
                } 
            });
        }
        else
        {
            var arr = [...allServices];
            
            arr.forEach((item,index) => {
                
                if(item.serviceID == response.data.addEditService.serviceID){
                    arr[index].serviceID             = response.data.addEditService.serviceID;
                    arr[index].sellAfterMainProduct  = response.data.addEditService.sellAfterMainProduct;
                    arr[index].news                  = response.data.addEditService.news;
                    arr[index].privileged            = response.data.addEditService.privileged;
                    arr[index].name                  = response.data.addEditService.name;
                    arr[index].price                 = response.data.addEditService.price;
                    arr[index].lang                  = response.data.addEditService.lang;
                }

            });
            cache.writeQuery({ 
                query:GET_SERVICES,
                variables:variables,
                data:{
                    allServices: arr
                } 
            });
        }
    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData()
    {
        var serviceID = 0;
        if(this.parent.props.selectedServiceID)serviceID = this.parent.props.selectedServiceID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_SERVICE,
            errorPolicy:"all",
            variables:{
                serviceID:serviceID,
                lang:"cz"
            },  
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors)
            this.parent.setState({apolloError:data.errors[0].message});
        
        //kdyz upravujeme zaznam
        if(data.data)
        {                  
            if(data.data.serviceWithLangs)
            {  
                var mainPhoto = "";
                if(data.data.serviceWithLangs.photo) mainPhoto = SERVER_URL + "/" + SERVICE_IMAGE_URL + "/stredni_" + data.data.serviceWithLangs.photo;
                
                var servicesToBuy = [];

                if(data.data.serviceWithLangs.servicesToBuy)
                {
                    for(let i = 0; i < data.data.serviceWithLangs.servicesToBuy.length; i++){
                        servicesToBuy.push(data.data.serviceWithLangs.servicesToBuy[i].buyServiceID);
                    }
                }

                let langs = this.completeLangsArray(data.data.serviceWithLangs.langs,data.data.allLanguageMutations);     
                this.parent.setState({
                    apolloError:"",
                    selectedServiceID:serviceID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allServices: data.data.allServices,
                    mainPhoto,
                    formData:{...this.parent.state.formData,
                        serviceID:            data.data.serviceWithLangs.serviceID,
                        news:                 data.data.serviceWithLangs.news,
                        privileged:           data.data.serviceWithLangs.privileged,
                        discountDateTo:       (data.data.serviceWithLangs.discountDateTo ? moment(data.data.serviceWithLangs.discountDateTo).toDate() : ""),
                        discountDateFrom:     (data.data.serviceWithLangs.discountDateFrom ? moment(data.data.serviceWithLangs.discountDateFrom).toDate() : ""),
                        sellAfterMainProduct: data.data.serviceWithLangs.sellAfterMainProduct,
                        langs:langs,
                        selectedServicesToBuy:servicesToBuy
                    }
                });
            }
            else
            {
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(serviceID == 0)
        {
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            
            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                allServices: data.data.allServices,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }
    }

    selectService = (e,serviceID) => {

        var checked = e.target.checked;
        var arr = [...this.parent.state.formData.selectedServicesToBuy];

        if(!checked){
            for(let i in this.parent.state.formData.selectedServicesToBuy){
                if(this.parent.state.formData.selectedServicesToBuy[i] == serviceID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [serviceID,...arr];
        }
        
        this.parent.setState({
            formData:{...this.parent.state.formData,selectedServicesToBuy:arr}
        });
  
    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {

        let langs = langsFromDb;
        for(const l of allActiveLanguages)
        {
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet)
            {
                langs.push({
                    name:"",
                    price:"",
                    discountPrice:"",
                    emailText:"",
                    description:"",
                    sampleFile:"",
                    file:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    setDiscountDate(date)
    {
        this.parent.setState({formData:{...this.parent.state.formData,discountDateTo: date}});
    }

    setDiscountDateFrom(date)
    {
        this.parent.setState({formData:{...this.parent.state.formData,discountDateFrom: date}});
    }

    openImage(e)
    {
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                mainPhoto:dataURL,
                formData:{...this.parent.state.formData,photo:photo}
            })
        };
        reader.readAsDataURL(photo);
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e)
    {
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});       
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangObjectHandle(e)
    {
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix,selectedCurrency:lang.currencyTitle}});
            }
        }      
    }
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e)
    { 
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName)
    {
        let langs = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }
}

export default Service;