/*
    Úvodní layout administrace
*/
import React,{ Component } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';

import AllCategories from '../Modules/Category/Components/AllCategories.js';
import AllVideos from '../Modules/Video/Components/AllVideos.js';
import AllServices from '../Modules/Service/Components/AllServices.js';
import AllForms from '../Modules/Form/Components/AllForms.js';
import AllArticles from '../Modules/Article/Components/AllArticles.js';
import AllMarketings from '../Modules/Marketing/Components/AllMarketings.js';
import Customer from '../Modules/Customer/Components/Customer';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Link from '../Modules/Content/Components/Link';
import Invoice from '../Modules/Invoice/Components/Invoice';
import InvoiceCreditNote from '../Modules/Invoice/Components/InvoiceCreditNote';
import Newsletter from '../Modules/Newsletter/Components/Newsletter';
import Campaign from '../Modules/Campaign/Components/Campaign';
import AllMemberships from '../Modules/Membership/Components/AllMemberships';


class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div className="wholeContent">			       
 	      
 	        <div id="layout">	
				<Header />  
				<Switch>
        
					<Route exact path='/customer' component={Customer}></Route>
					<Route exact path='/content/link' component={Link}></Route>
					<Route exact path='/content/article' component={AllArticles}></Route>
					<Route exact path='/content/categories' component={AllCategories}></Route>
					<Route exact path='/content/videos' component={AllVideos}></Route>
					<Route exact path='/order/invoice' component={Invoice}></Route>
					<Route exact path='/order/credit-note' component={InvoiceCreditNote}></Route>
					<Route exact path='/content/services' component={AllServices}></Route>
					<Route exact path='/content/marketing' component={AllMarketings}></Route>
					<Route exact path='/content/forms' component={AllForms}></Route>
					<Route exact path='/content/membership' component={AllMemberships}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					<Route exact path='/newsletter' component={Newsletter}></Route>
					<Route exact path='/campaign' component={Campaign}></Route>
					
				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  }
}

export default withAdminAuthentication(Layout);
