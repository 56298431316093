/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
 export const ADD_EDIT_SERVICE = gql`
 mutation AddEditService(
    $serviceID:ID,
    $photo:Upload,
    $sellAfterMainProduct:ID,
    $news:Int,
    $privileged:Int,
    $discountDateTo:String,
    $discountDateFrom:String,
    $langs:[ServiceLangsInput],
    $selectedServicesToBuy:[ID]
){
     addEditService(
        serviceID:$serviceID, 
        photo:$photo, 
        sellAfterMainProduct:$sellAfterMainProduct,
        news:$news,
        privileged:$privileged,
        discountDateTo:$discountDateTo,
        discountDateFrom:$discountDateFrom,
        langs:$langs,
        selectedServicesToBuy:$selectedServicesToBuy
    ){
         serviceID
         sellAfterMainProduct
         news
         privileged
         name
         price
         lang
     }
 }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_SERVICE_PRIORITY = gql`
    mutation updateServicePriority($serviceID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateServicePriority(serviceID:$serviceID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

 export const GET_SERVICE = gql`
 query ServiceWithLangs($serviceID: ID!, $lang:String){
     serviceWithLangs(serviceID:$serviceID){
         serviceID
         sellAfterMainProduct
         news
         privileged
         discountDateTo
         discountDateFrom
         photo
         langs{
             lang
             name
             price
             discountPrice
             emailText
             description
             sampleFile
             file
         } 
         servicesToBuy{
             buyServiceID 
         }   
     }
     allLanguageMutations(onlyEnabled:true){
         languageID
         title
         suffix
         file
         generateNiceTitle
         langTitle
         decimal
         priority
         main
         currencyTitle
     }
     allServices(lang: $lang){
         serviceID
         name
     }
 }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_SERVICES = gql`
    query AllServices($lang: String!){
        allServices(lang: $lang){
            serviceID
            sellAfterMainProduct
            news
            privileged
            discountDateTo
            discountDateFrom
            name
            price
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_SERVICES = gql`
    mutation deleteServices($serviceIDs:ID!) {
        deleteServices(serviceIDs:$serviceIDs)
    }
`;

/**
 * vyhledání služeb
 */
export const SEACRH_SERVICES = gql`

    query SearchServices($text: String!,$lang:String){
        searchServices(text:$text,lang:$lang){
            name
            serviceID
        }

    }

`;